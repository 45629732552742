<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <!-- Header -->
    <div class="flex justify-between items-center border-b border-gray-200 pb-8">
      <h3 class="text-base md:text-24 leading-tight font-bold font-heading text-gray-800">Platforms</h3>

      <!-- Action buttons -->
      <div class="flex-shrink-0">
        <AppButton
          :to="{ name: routeNames.editPlatforms }"
          variant="primary"
          size="standart-sm"
        >
          Edit
          <i class="icon-edit-pencil text-12 ml-8" />
        </AppButton>
      </div>
    </div>

    <div class="w-full flex pt-16 flex-1 flex-col overflow-y-auto pb-8 md:pb-40">
      <div class="w-full">
        <p class="font-medium text-left mb-4 text-blue-600 whitespace-normal text-18">Basic information</p>
        <div class="w-full mt-10">
          <div
            v-for="(item, index) in platforms"
            :key="index"
            class="w-full flex flex-col sm:flex-row mb-16 sm:mb-8"
          >
            <p class="min-w-192 w-192 text-gray-900 font-heading font-medium mr-16 whitespace-normal capitalize">
              {{ item.platform }}
            </p>
            <span class="text-gray-700 break-all whitespace-normal">
              Is active: {{ booleanValueToLabel(item.active) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

import { routeNames } from '@/router'
import { notification, booleanValueToLabel } from '@/core/helpers'
import { usePlatforms } from '@/core/compositions'

import AppButton from '@/components/stateless/AppButton.vue'

export default defineComponent({
  name: 'Platforms',

  components: {
    AppButton
  },

  setup () {
    const { platforms, getPlatforms } = usePlatforms()

    const loading = ref(true)

    const getInitialData = async () => {
      loading.value = true

      try {
        await getPlatforms()
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    // Run on start
    getInitialData()

    return {
      routeNames,
      loading,
      platforms,
      booleanValueToLabel
    }
  }
})
</script>
